import React, { useState, useEffect } from 'react';
import { Button, CircularProgress, Typography, Box, FormControl, InputLabel, Select, MenuItem, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { UploadCloud, Save, Delete } from 'lucide-react';
import moment from 'moment-timezone';

const API_URL = '/api';

const CSVUpload = ({ accessToken }) => {
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadResult, setUploadResult] = useState(null);
  const [selectedTimezone, setSelectedTimezone] = useState(moment.tz.guess());
  const [recentEntries, setRecentEntries] = useState([]);
  const [limit, setLimit] = useState(10);
  const [isEditing, setIsEditing] = useState({});

  useEffect(() => {
    fetchRecentEntries();
  }, [limit]);

  const fetchRecentEntries = async () => {
    try {
      const response = await fetch(`${API_URL}/glucose_readings/recent?limit=${limit}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        setRecentEntries(data);
      }
    } catch (error) {
      console.error('Error fetching recent entries:', error);
    }
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      alert('Please select a file first!');
      return;
    }

    setIsUploading(true);
    setUploadResult(null);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('timezone', selectedTimezone);

    try {
      const response = await fetch(`${API_URL}/upload_csv/`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`
        },
        body: formData
      });

      const result = await response.json();

      if (response.ok) {
        setUploadResult({
          success: true,
          message: `Successfully ingested ${result.count} entries.`
        });
        fetchRecentEntries();
      } else {
        setUploadResult({
          success: false,
          message: result.detail || 'Failed to ingest CSV data.'
        });
      }
    } catch (error) {
      console.error('Error uploading CSV:', error);
      setUploadResult({
        success: false,
        message: 'An error occurred while uploading the CSV.'
      });
    } finally {
      setIsUploading(false);
    }
  };

  const handleEdit = (entry) => {
    setIsEditing(prev => ({
      ...prev,
      [entry._id]: {
        ...entry,
        timestamp: moment(entry.timestamp).format('YYYY-MM-DDTHH:mm')
      }
    }));
  };

  const handleSave = async (id) => {
    try {
      const response = await fetch(`${API_URL}/glucose_readings/${id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(isEditing[id])
      });

      if (response.ok) {
        fetchRecentEntries();
        setIsEditing(prev => {
          const newState = { ...prev };
          delete newState[id];
          return newState;
        });
      }
    } catch (error) {
      console.error('Error updating entry:', error);
    }
  };

  const handleDelete = async (id) => {
    if (!window.confirm('Are you sure you want to delete this entry?')) return;

    try {
      const response = await fetch(`${API_URL}/glucose_readings/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });

      if (response.ok) {
        fetchRecentEntries();
      }
    } catch (error) {
      console.error('Error deleting entry:', error);
    }
  };

  return (
    <Box sx={{ mt: 3 }}>
      <Typography variant="h6" gutterBottom>
        Upload CGM Readings CSV
      </Typography>

      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mb: 3 }}>
        <input
          accept=".csv"
          style={{ display: 'none' }}
          id="raised-button-file"
          type="file"
          onChange={handleFileChange}
        />
        <label htmlFor="raised-button-file">
          <Button variant="outlined" component="span" startIcon={<UploadCloud />}>
            Select CSV File
          </Button>
        </label>

        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>Timezone</InputLabel>
          <Select
            value={selectedTimezone}
            onChange={(e) => setSelectedTimezone(e.target.value)}
            label="Timezone"
          >
            {moment.tz.names().map((tz) => (
              <MenuItem key={tz} value={tz}>{tz}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          variant="contained"
          onClick={handleUpload}
          disabled={!file || isUploading}
        >
          Upload and Ingest
        </Button>

        {isUploading && <CircularProgress size={24} />}
      </Box>

      {file && <Typography variant="body2" sx={{ mt: 1 }}>{file.name}</Typography>}

      {uploadResult && (
        <Typography
          variant="body1"
          sx={{ mt: 2, mb: 3, color: uploadResult.success ? 'green' : 'red' }}
        >
          {uploadResult.message}
        </Typography>
      )}

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
        <Typography variant="h6">Recent Entries</Typography>
        <TextField
          type="number"
          label="Limit"
          value={limit}
          onChange={(e) => setLimit(Number(e.target.value))}
          size="small"
          sx={{ width: 100 }}
        />
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Timestamp</TableCell>
              <TableCell>Value</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {recentEntries.map((entry) => (
              <TableRow key={entry._id}>
                {isEditing[entry._id] ? (
                  <>
                    <TableCell>
                      <TextField
                        type="datetime-local"
                        value={isEditing[entry._id].timestamp}
                        onChange={(e) => setIsEditing(prev => ({
                          ...prev,
                          [entry._id]: {
                            ...prev[entry._id],
                            timestamp: e.target.value
                          }
                        }))}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        value={isEditing[entry._id].value}
                        onChange={(e) => setIsEditing(prev => ({
                          ...prev,
                          [entry._id]: {
                            ...prev[entry._id],
                            value: Number(e.target.value)
                          }
                        }))}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      <Button
                        startIcon={<Save />}
                        onClick={() => handleSave(entry._id)}
                        size="small"
                      >
                        Save
                      </Button>
                    </TableCell>
                  </>
                ) : (
                  <>
                    <TableCell>
  {isEditing[entry._id] ? (
    <TextField
      type="datetime-local"
      value={isEditing[entry._id].timestamp}
      onChange={(e) => setIsEditing(prev => ({
        ...prev,
        [entry._id]: {
          ...prev[entry._id],
          timestamp: e.target.value
        }
      }))}
      size="small"
    />
  ) : (
    <div>
      <div>{moment.utc(entry.timestamp).format('YYYY-MM-DD HH:mm:ss [UTC]')}</div>
      <div style={{ color: 'gray', fontSize: '0.9em' }}>
        ({moment(entry.timestamp).format('YYYY-MM-DD HH:mm:ss z')} local)
      </div>
    </div>
  )}
</TableCell>
                    <TableCell>{entry.value}</TableCell>
                    <TableCell>
                      <Button
                        onClick={() => handleEdit(entry)}
                        size="small"
                      >
                        Edit
                      </Button>
                      <Button
                        color="error"
                        startIcon={<Delete />}
                        onClick={() => handleDelete(entry._id)}
                        size="small"
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CSVUpload;